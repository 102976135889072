* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background-color: white;
  color: rgb(222,101,26);
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
}

h1,
h4 {
  margin: 0.5em;
}

.profile-icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -350px;
  margin-right: 10px;
  
}
.profile-dropdawn{
  background-color: transparent;
}
.error {
  margin-bottom: 0.5rem;
}
.eloaON {
    margin-top: 4rem !important;
    text-align: center;
}